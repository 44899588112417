<template>
  <div class="d-flex">
    <v-sheet :color="wsLIGHTCARD" class="px-6 py-2" style="border-radius: 24px">
      <h4 class="font-weight-regular">{{ text }}</h4>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "categoryChip",
  props : {
    text : {
      type : String,
    },
    value : {
      type : String,
    }
  }
}
</script>

<style scoped>

</style>